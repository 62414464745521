.orgcomp__menu{
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 47px;
    right: 0;
    width: 100%;
    //box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border:1px solid #DBDBDB !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    overflow: auto;
    height: auto !important;
    max-height: 380px !important;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
}

.orgcomp__menu::-webkit-scrollbar {
    display: none;
}

.orgcomp__menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.orgcomp__menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.org__email {
    padding: 4px 8px !important;
}
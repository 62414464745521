.item-enter {
    opacity: 1;
  }
  .item-enter-active {
    opacity: 1;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    transform: scale(1, 0.2);
    opacity: 0.2;
    transition: all 500ms ease-in;
  }

  @keyframes shrinkAnimation {
    0% {
      transform: scale(1, 1);
      opacity: 1;
    }

    50% {
      transform: scale(1, 0.5);
      opacity: 0.5;
    }

    100% {
      transform: scale(1, 0);
      opacity: 0;
    }
  }

.borderRightStyle {
    border-right: 1px solid #d4d9dd;
    padding: 0px 16px
    
}

.borderRightStyle:last-child {
    border-right: none !important;
}

.texStyles {
    color: #6a7685 !important;
    font-size: 13px !important;
    line-height: 20px !important; 
    opacity: .75 !important;
}

.valueTextStyles {
    color: #4a5568 !important;
    font-size: 20px !important;
    line-height: 28px !important;
}

.pastDueInvoiceStyle {
    border-right: 1px solid #d4d9dd;
    padding: 24px;
}

.pastDueAmountStyle {
    font-size: 16px !important;
    color: #4a5568 !important;
    font-weight: 500 !important;
    line-height: 28px !important;
}

.pastDueCountStyle {
    font-size: 13px !important;
    color: #6a7685 !important;
    font-weight: 500 !important;
    margin-bottom: 16px !important;
}

.pastDueRangeStyle {
    font-size: 13px !important;
    color: #6a7685 !important;
    font-weight: 500 !important;
}

.pastDueTextStyle {
    font-size: 16px !important;
    color: #212529 !important;
    font-weight: 600 !important;
}

.pastDueTotalStyle {
    font-size: 16px !important;
    color: #f32300 !important;
    font-weight: 600 !important;
}

.totalOutstandingContainerStyle {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-end;    
    align-items: flex-end;
}

.totalOutstandingAmountStyle {
    font-size: 20px !important;
    color: #f76707 !important;
    line-height: 32px !important;

}
.react-datepicker{
    /* border-left:  1px solid #ccc !important;
    border-right:  1px solid #ccc !important;
    border-bottom:  1px solid #ccc !important;
    border-bottom:  1px solid #ccc !important; */
    border: none !important;
    border-radius: 0 !important;
    width: 238px !important;
  }
  
  .react-datepicker__header{
    background-color: transparent !important;
  }
  
  .react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    border-width: 1px 1px 0 0 !important;
    border-color: #212121 !important;
  }
  
  .MuiPaper-elevation8 {
    box-shadow: none !important;
    border: 1px solid #ccc !important;
  }

  @media print {
    #printInvoice {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }

    #customPrintStyle {
      padding: 8px !important;
    }

    #hideOnPrint1 {
      display: none !important;
    }

    #hideOnPrint2 {
      display: none !important;
    }

    .hideOnPrint {
      display: none !important;
    }

}

.arrow {
  position: absolute;
  top: -10px; /* Position the arrow above the step */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e0e0e0; /* Create an up arrow */
}

.addButton {
  color: white !important;
  background-color: #3d3d3d !important;
  margin-left: auto !important;
  height: 30px !important;
  margin-right: 8px !important;
}

.addButton:hover {
  background-color: #3d3d3d !important;
}

.buttonPlusIcon {
  font-size: 1.2rem !important;
  align-items: center !important;
}

.outboardingLabel {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.upArrow_styles {
  width: 52px !important; 
  position: relative !important; 
  top: 27px !important;
  z-index: 11 !important;
}

.MuiAccordionSummary-content.Mui-expanded{
  margin: 0 0 !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 0 !important;
}

.spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

.spinner:hover {
  background: none !important;
}
//-----------------email scheduler mention overridden styling-----------
.mentionWrapper {
    //z-index: 999 !important;
    position: relative;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input {
    width: 100%;
    //background: transparent;
    //font-size: 0.9rem;
    color: gray; // #a9b5c4;
    margin: 4px auto; //8px;
    border: 1px solid rgb(219, 219, 219) !important;
    padding: 8px 0px 8px 12px !important;
    border-radius: 5px !important;
    position: relative;
}

.mentionWrapper--singleLine .mentionWrapper__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentionWrapper--multiLine .mentionWrapper__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;

}

.mentionWrapper--multiLine .mentionWrapper__highlighter {
    padding: 9px;
}

.mentionWrapper--multiLine .mentionWrapper__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
    margin-left: 16px;
}
.mentionWrapper__suggestions{
    z-index: 999 !important;
}
.mentionWrapper__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentionWrapper__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentionWrapper__suggestions__item--focused {
    background-color: silver !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input::placeholder {
    color: #ccc; //#7288a3;
    font-weight: 400;
    font-size: 14px;
}


.mentionWrapper .mentionWrapper__control {
    height: 30px !important;
    min-height: 30px !important;
    border: none;
    z-index: 999 !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
    padding: 0.7rem 1rem;
    background-color: white;
    max-height: 100% !important;
}

.rdw-suggestion-dropdown {
    width: 350px;
    border-radius: 5px !important;
    position: absolute;
    left: auto !important;
    right: auto !important;
}

.rdw-suggestion-dropdown .rdw-suggestion-option:hover {
    cursor: pointer;
    background-color: silver !important;
}

//-----------------email scheduler mention overridden styling-----------  
//editor schedulor editor
.public-DraftStyleDefault-block {
    margin: 0;
}
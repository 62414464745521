.create_doc_layout {
    display: flex;
    overflow-y:visible;
    overflow-x:hidden;
    position: relative;
}

.drag_toolbox {
    width: 60px;
    padding: 20px;
    height: 100vh;
    background-color: #eef5ff;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: sticky;
    top: 0
}

.drag_toolbox_item {
    cursor: move;
}

.drag_toolbox_item_icon {
    font-size: 48px !important;
}

.drag_toolbox_item_image {
    width: 48px !important;
}

.pdf_container {
    height: 100%;
    padding: 24px;
    display: flex !important;
    justify-content: center;
    
    canvas {
        width: 100% !important;
        height: auto !important;
        display: block;
    }
}

.pdf_container_scroll {
    height: calc(100vh - 70px);
    padding: 24px;
    display: flex !important;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    
    canvas {
        width: 100% !important;
        height: auto !important;
        display: block;
    }
}

.pdf_container_scroll::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.react-pdf__Page {
    margin-bottom: 20px;
    width: 100%;
}

.dropped-item {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 100px;
}

.signature-container {
    width: 300px;
    z-index: 100;
}

.signature-canvas {
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.signature-container .signature-canvas {
    background-color: #fff !important;
}

.remove_dropped_item {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    color: red;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_item {
    top: -8px;
    right: -28px;
}

.sign_item_item {
    top: -8px;
    right: -10px;
}

.date_item {
    top: -8px;
    right: -10px;
}

.dropped_item_close_icon {
    font-size: 15px !important;
}

.form_container {
    height: calc(100vh - 70px);
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid rgba(0,0,0,0.1);
    scrollbar-width: none; /* For Firefox */
}

.form_container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.create_doc_btn {
    width: 170px !important;
    background-color: #00B13F !important;
    color: #fff !important;
}

.dropped_sign_image_container {
    background: white !important;
    width: 100% !important;
    height: 100px !important; 
    border: 1px solid rgba(0,0,0,0.1) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: relative !important;
}

.dropped_sign_image_container1 {
    background: white !important;
    border: 1px solid rgba(0,0,0,0.1) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.sign_image {
    width: 100% !important; 
    height: 100px !important;
    object-fit: contain !important;
}

.loading_style {
    margin-right: 8px !important;
    color: #fff !important;
}

.doc_draft__status {
    background: #828c98;
}

.doc_pending__status {
    background: #8b0000;
}

.doc_void__status {
    background: #140126;
}

.doc_completed__status {
    background: green;
}


.doc_draft__background {
    background-color: rgba(130, 140, 152, 0.1);
}

.doc_pending__background {
    background-color: rgba(139, 0, 0, 0.1);
}

.doc_void__background {
    background-color: rgba(20, 1, 38, 0.1);
}

.doc_completed__background {
    background-color: rgba(0, 128, 0, 0.1);
}
